import { get, isNil, set } from 'lodash';
import { toWebSafeFormat } from '@/utilities/strings';
import getTiersMapping from '@/mappings/tiers';
import compileChangeObject from './compile-change-object';
import { isEmpty } from '../validation';

export default (changeArray, sharedData, getAdminConfigValue) => {
  if (!changeArray?.length) return {};

  const TIERS_MAPPING = getTiersMapping();
  const productName = sharedData?.product;
  const { signupBccEmailMerchantRequestNotification } = getAdminConfigValue('emailAddresses');
  const returnSpeed = getAdminConfigValue('returnSpeed');
  const { disablePaperlessInAdminOptions } = getAdminConfigValue('features');
  const allowsInternationReturns = getAdminConfigValue('allowsInternationalReturns');
  let changeObject = {};

  changeObject = compileChangeObject(changeArray, productName);

  if (changeObject.products?.[productName]?.customerPayments?.pricingTier) {
    const pricingTier = changeObject.products?.[productName].customerPayments.pricingTier;
    changeObject.products[productName].customerPayments.consumerChargeAccount = TIERS_MAPPING[pricingTier].CONSUMER_CHARGE_ACCOUNT;
    changeObject.products[productName].customerPayments.consumerApiKey = TIERS_MAPPING[pricingTier].API_KEY;
  }

  // eslint-disable-next-line max-len
  if (sharedData && changeObject.products[productName].customerPayments && !changeObject.products[productName].customerPayments.merchantChargeAccount) {
    changeObject.products[productName].customerPayments.merchantChargeAccount = sharedData.applicant?.merchantChargeAccount ?? null;
    changeObject.products[productName].customerPayments.locationId = sharedData.applicant?.locationId ?? null;
  }

  // set accountId from applicant if not present in changes array
  const carrierApiSecretChange = get(changeObject, `products.${productName}.carrierConfig.carriers[0].apiSecret`);
  const carrierAccountIdChange = get(changeObject, `products.${productName}.carrierConfig.carriers[0].accountId`);
  if (sharedData && carrierApiSecretChange && !carrierAccountIdChange) {
    set(changeObject, `products.${productName}.carrierConfig.carriers[0].accountId`, sharedData.applicant.accountId);
  }
  // get the info if the merchant has international returns enabled only if the portal allows it
  if (allowsInternationReturns) {
    // get the value from the changes array
    const internationalReturns = get(changeObject, `products.${productName}.returnsPortalInternational`);
    // get the value from the shared data
    const sharedInternationalReturns = sharedData?.applicant?.returnsPortalInternational ?? false;
    // if the value is not present in the changes array, get it from the shared data
    if (isNil(internationalReturns)) {
      set(changeObject, `products.${productName}.returnsPortalInternational`, sharedInternationalReturns);
    }
  }

  if (sharedData) {
    const companyId = sharedData.companyName;
    const isIntegratedMerchantFromChanges = get(changeObject, `products.${productName}.isIntegratedMerchant`);
    const isPaperlessFromChanges = get(changeObject, `products.${productName}.paperlessOptionEnabled`);
    const isIntegratedMerchantFromApplicant = get(sharedData, 'applicant.premiumPortal');
    const usernameFromChanges = get(changeObject, `products.${productName}.username`);
    const usernameFromApplicant = get(sharedData, 'applicant.username');

    changeObject.companyId = toWebSafeFormat(companyId);
    changeObject.redirectUrl = `${window.location.origin}/${companyId?.toUpperCase()}/login`;
    changeObject.companyName = companyId;

    changeObject.login = sharedData.applicant?.emailAddress;
    changeObject.contactName = sharedData.applicant?.contactName;
    changeObject.phoneNumber = sharedData.applicant?.phoneNumber;
    changeObject.legalEntity = sharedData.applicant?.legalEntity;
    changeObject.requestId = sharedData.requestId;
    changeObject.returns = { returnsSpeedType: returnSpeed };

    if (!isEmpty(signupBccEmailMerchantRequestNotification)) {
      changeObject.bcc = [
        {
          name: companyId,
          email: signupBccEmailMerchantRequestNotification,
        },
      ];
    }

    if (isNil(isIntegratedMerchantFromChanges) && !isNil(isIntegratedMerchantFromApplicant)) {
      set(changeObject, `products.${productName}.isIntegratedMerchant`, isIntegratedMerchantFromApplicant);
    }

    if (isNil(isPaperlessFromChanges) && disablePaperlessInAdminOptions) {
      set(changeObject, `products.${productName}.paperlessOptionEnabled`, false);
    }
    if (!usernameFromChanges && usernameFromApplicant) {
      set(changeObject, `products.${productName}.username`, usernameFromApplicant);
    }
  }
  return changeObject;
};
