import company from './company';
import assetManagement from './asset-management';
import warehouse from './warehouse';
import warehouseBindings from './warehouse-bindings';
import onboard from './onboard';
import signup from './signup';
import companyCarrier from './company-carrier';
import companyNetwork from './company-network';

const publish = async ({
  changes,
  merchantId,
  configCarrier,
  token,
  rootOrgId,
  productName,
  warehouseAddress,
  applicationVersion,
  getConfigValue,
  getFeature,
  getAdminFeature,
  sharedData,
  getAdminConfigValue,
  getLocaleContextValue,
  newAddressEnabledByDefault,
  homeCountryCode,
  homeCountryCodeSetToWarehouse,
}) => {
  const getChanges = (type) => changes.filter((change) => change.type === type);
  await company(getChanges('COMPANY'), merchantId, token, rootOrgId, productName, getConfigValue, getFeature, sharedData, getAdminConfigValue);
  await companyCarrier(getChanges('COMPANY_CARRIER_CONFIG'), merchantId, token, rootOrgId, productName, getConfigValue, getFeature, sharedData);
  await companyNetwork(getChanges('COMPANY_NETWORK_CONFIG'), merchantId, token, rootOrgId, productName, getConfigValue, getFeature, sharedData);
  await warehouse(getChanges('WAREHOUSE').concat(getChanges('WAREHOUSE_BINDINGS')), merchantId, token, rootOrgId, warehouseAddress, newAddressEnabledByDefault, homeCountryCode, homeCountryCodeSetToWarehouse, getFeature);
  await warehouseBindings(getChanges('WAREHOUSE_BINDINGS'), merchantId, token, rootOrgId, getFeature, sharedData.locationId);
  await onboard(getChanges('ONBOARD'), token, rootOrgId, productName, getAdminConfigValue, getFeature, sharedData);
  await signup(getChanges('SIGNUP'), token, rootOrgId, productName, getAdminFeature('signup')?.canSkipPrereg);
  await assetManagement(getChanges('APPLICATION_CONFIG_MERCHANT'), merchantId, token, productName, applicationVersion, getConfigValue, getFeature, getLocaleContextValue);
  await assetManagement(getChanges('APPLICATION_CONFIG_OWNER'), configCarrier, token, productName, applicationVersion, getConfigValue, getFeature, getLocaleContextValue);
};

export default publish;
