/* eslint-disable no-console */
import { isEmpty } from '@/utilities/validation';
import compileChangeObject from './compile-change-object';
import { updateWarehouseReq } from './warehouse';

export default async (changeArray, merchantId, token, rootOrgId, getFeature, locationId) => {
  if (isEmpty(changeArray) || isEmpty(locationId)) return;

  const { multiCarrierAddressesApiEnabled } = getFeature('addresses');

  const body = {
    enabled: multiCarrierAddressesApiEnabled ? undefined : true,
    ...compileChangeObject(changeArray),
  };

  try {
    await updateWarehouseReq({
      locationId,
      rootOrgId,
      merchantId,
      body,
      token,
      multiCarrierAddressesApiEnabled,
    });
  } catch (e) {
    console.log('Could not update the warehouse bindings');
    console.log(e);
    throw new Error(e);
  }
};
