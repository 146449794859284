/* eslint-disable no-console */
import { isEmpty } from '@/utilities/validation';
import { isBoolean, includes } from 'lodash';
import { updateCompanyRequest } from './company';
import compileChangeObject from './compile-change-object';
import { errorHandler } from '../error-handling';

export default async (changeArray, merchantId, token, rootOrgId, productName, getConfigValue, getFeature, { carrierCompanyId }) => {
  const { multiCarrierEnabled, carriersWithPendingStatus } = getFeature('carriers') || {};
  const carrierNetworksEnabled = getFeature('carriers').carrierNetworksEnabled || [];
  let earlyReturn;

  if (isEmpty(changeArray) && (!multiCarrierEnabled && includes(carrierNetworksEnabled, carrierCompanyId))) {
    earlyReturn = false;
  } else if (isEmpty(changeArray)) {
    earlyReturn = true;
  }

  if (earlyReturn) return;
  const changes = compileChangeObject(changeArray);
  const currentCarriers = getConfigValue('carriersConfig') || [];
  const existingCarrier = currentCarriers.find((el) => el.carrierCompanyId === carrierCompanyId);

  const carrierShouldBePending = carriersWithPendingStatus && carriersWithPendingStatus.includes(carrierCompanyId);
  const hasDefaultCarrier = currentCarriers.some((el) => el.default);

  let updatedCarrier;

  const defaultCarrier = {
    carrierCompanyId,
    ...changes,
  };

  if (!existingCarrier) {
    updatedCarrier = {
      enabled: multiCarrierEnabled ? !carrierShouldBePending : true,
      default: multiCarrierEnabled ? !!(!carrierShouldBePending && !hasDefaultCarrier) : true,
      ...defaultCarrier,
    };
  } else {
    updatedCarrier = {
      ...existingCarrier,
      ...changes,
    };
  }

  let updatedList = currentCarriers
    .filter((el) => el.carrierCompanyId !== carrierCompanyId);

  if (!multiCarrierEnabled) {
    // for non-multi carrier - toggle rest of the carriers off, when updating a carrier
    updatedList = [];
    if (!carrierShouldBePending) {
      updatedCarrier.default = true;
    }
  }

  if (includes(carrierNetworksEnabled, carrierCompanyId)) {
    updatedCarrier = [];
    if (multiCarrierEnabled) {
      updatedList = currentCarriers
        .filter((el) => el.carrierCompanyId !== carrierCompanyId);
    }
  }

  if (changes.default) {
    updatedList = updatedList.map((el) => ({ ...el, default: false }));
    updatedCarrier.default = true;
  }

  const body = {
    products: {
      [productName]: {
        carrierConfig: {
          carriers: []
            .concat(updatedList)
            .concat(isBoolean(updatedCarrier.deleteCarrier) && updatedCarrier.deleteCarrier === true ? [] : updatedCarrier),
        },
        enabled: true,
      },
    },
  };

  try {
    await updateCompanyRequest({
      rootOrgId, merchantId, body, token,
    });
  } catch (e) {
    console.log('Could not update company carrier config');
    console.log(e);
    throw new Error(errorHandler.checkInvalidCarrierError(e));
  }
};
